/******* FONTS *******/
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap');

@font-face {
  font-family: 'accounteer regular';
  src: url('./assets/fonts/Manrope-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'accounteer medium';
  src: url('./assets/fonts/Manrope-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'accounteer semi';
  src: url('./assets/fonts/Manrope-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'accounteer bold';
  font-weight: 700;
  src: url('./assets/fonts/Manrope-Bold.woff2') format('woff2');
}

/******* GLOBAL STYLES *******/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

::selection {
  background-color: #f3f8ff;
  color: #4e5a2d;
}

html {
  // font-size: calc(100vw / 1512 * 10);
  font-size: 62.5%;
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'accounteer regular';

  @media only screen and (max-width: 62.5em) {
    font-size: 56.25%;
  }

  @media only screen and (max-width: 56.25em) {
    font-size: 54%;
  }

  @media only screen and (max-width: 37.5em) {
    font-size: 51%;
  }

  @media only screen and (min-width: 140.5em) {
    font-size: 75%;
  }
}

body {
  font-size: 1.6rem;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

// Change Autocomplete styles in Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #575f6a;
  -webkit-box-shadow: 0 0 0 0 #f5f7f8 inset;
  transition: background-color 5000s ease-in-out 0s;
  font-family: 'accounteer medium', sans-serif;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background-color: #888;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
